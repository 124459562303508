import { Config as ConfigType } from './types';
import { user } from './shli/user';
import { expert } from './shli/expert';
import { client } from './shli/client';
import { company } from './shli/company';
import { mission } from './shli/mission';
import { invitation } from './shli/invitation';
import { candidate } from './shli/candidate';
import { candidacy } from './shli/candidacy';
import { proposal } from './shli/proposal';
import { quotation } from './shli/quotation';
import { charge } from './shli/charge';
import { milestone } from './shli/milestone';
import { contract } from './shli/contract';
import { contractMilestone } from './shli/contractMilestone';
import { invoice } from './shli/invoice';
import { recommendation } from './shli/recommendation';
import { legalInformation } from './shli/legalInformation';
import { cgu } from './shli/cgu';
import { match } from './shli/match';
import { document } from './shli/document';
import { subCategory } from './shli/subCategory';
import { documentFile } from './shli/documentFile';
import { documentsCompanies } from './shli/documentsCompanies';
import { tag } from './shli/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '4rr0tdosatvzb70almrhxtkbk',
  locale: 'en',
  name: 'shli',
  dashboard: true,
  theme: {
    primary: '#182134',
    secondary: '#7BDCCD',
    info: '#6460BA',
  },
  defaultCountry: 'ae',
  preferredCountries: ['ae', 'sa', 'eg', 'jo', 'in'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    invitation,
    candidate,
    candidacy,
    proposal,
    quotation,
    charge,
    milestone,
    contract,
    contractMilestone,
    invoice,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
  ],
};

export default config;
