import { Resource as ResourceType } from '../types';

export const invoice: ResourceType = {
  name: 'invoices',
  label: 'resource.invoices.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.invoices.field.id.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.invoices.field.company_name.label',
      type: 'text',
    },
    {
      key: 'total_ttc',
      label: 'resource.invoices.field.total_ttc.label',
      type: 'number',
    },
    {
      key: 'total_ht',
      label: 'resource.invoices.field.total_ht.label',
      type: 'number',
    },
    {
      key: 'guid',
      label: 'resource.invoices.field.guid.label',
      type: 'text',
    },
    {
      key: 'pdfs',
      label: 'resource.invoices.field.pdfs.label',
      type: 'buttons',
    },
    {
      key: 'contract_id',
      label: 'resource.invoices.field.contract_id.label',
      type: 'reference',
      reference: 'contracts',
    },
    {
      key: 'contract_milestone_id',
      label: 'resource.invoices.field.contract_milestone_id.label',
      type: 'reference',
      reference: 'contract_milestones',
    },
    {
      key: 'due_date',
      label: 'resource.invoices.field.due_date.label',
      type: 'date',
    },
    {
      key: 'user_kind',
      label: 'resource.invoices.field.user_kind.label',
      type: 'select',
      choices: [
        {
          id: 'expert',
          name: 'resource.invoices.field.user_kind.choices.expert.label',
        },
        {
          id: 'client',
          name: 'resource.invoices.field.user_kind.choices.client.label',
        },
      ],
    },
    {
      key: 'status',
      label: 'resource.invoices.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'created',
          name: 'resource.invoices.field.status.choices.created.label',
        },
        {
          id: 'delayed',
          name: 'resource.invoices.field.status.choices.delayed.label',
        },
        {
          id: 'payed',
          name: 'resource.invoices.field.status.choices.payed.label',
        },
      ],
    },
    {
      key: 'created_at',
      label: 'resource.invoices.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.invoices.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'mission_id',
      label: 'resource.invoices.field.mission_id.label',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'proposal_id',
      label: 'resource.invoices.field.proposal_id.label',
      type: 'reference',
      reference: 'proposals',
    },
    {
      key: 'company_id',
      label: 'resource.invoices.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'client_company_id',
      label: 'resource.invoices.field.client_company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'leader_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'client_owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'contract_milestone',
      type: 'object',
      shape: [
        {
          key: 'payment_period',
          label: 'resource.contract_milestones.field.payment_period.label',
          type: 'select',
          choices: [
            {
              id: 'thirty_days',
              name: 'resource.contract_milestones.field.payment_period.choices.thirty_days_x.label',
            },
            {
              id: 'fifteen_days',
              name: 'resource.contract_milestones.field.payment_period.choices.fifteen_days_x.label',
            },
            {
              id: 'seven_days',
              name: 'resource.contract_milestones.field.payment_period.choices.seven_days.label',
            },
            {
              id: 'immediate',
              name: 'resource.contract_milestones.field.payment_period.choices.immediate.label',
            },
          ],
        },
        {
          key: 'discount',
          label: 'resource.proposals.field.discount.label',
          type: 'number',
        },
        {
          key: 'vat',
          label: 'resource.contract_milestones.field.vat.label',
          type: 'number',
        },
      ],
    },
    {
      key: 'billing_date',
      type: 'date',
      label: 'resource.invoices.field.invoices_at.label',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.invoices.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
      ],
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          name: 'client_company_id',
          type: 'reference',
          reference: 'companies',
          label: 'resource.invoices.field.client_company_id.label',
          filter: { user_kind: 'client' },
          child: {
            type: 'autocomplete',
            optionText: 'name',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          name: 'company_id',
          type: 'reference',
          reference: 'companies',
          label: 'resource.invoices.field.company_id.label',
          filter: { user_kind: 'expert' },
          child: {
            type: 'autocomplete',
            optionText: 'name',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
      ],
      fields: [
        {
          source: 'guid',
        },
        {
          source: 'company_name',
        },
        {
          source: 'status',
        },
        {
          source: 'user_kind',
        },
        {
          source: 'due_date',
        },
        {
          source: 'pdfs',
          sortable: false,
          extensions: [
            {
              key: 'multipleLangFiles',
            },
          ],
        },
        {
          source: 'total_ht',
        },
        {
          source: 'total_ttc',
        },
        {
          source: 'contract_milestone_id',
          child: {
            source: 'contract_milestones.title',
          },
        },
        {
          source: 'contract_id',
          child: {
            source: 'contracts.id',
          },
        },
        {
          source: 'billing_date',
        },
      ],
    },
    edit: {
      actions: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.invoices.bulk_action_buttons.validate.label',
          values: { validated: true },
          extraUrl: '/',
          disabled: [
            {
              property: 'status',
              op: 'not_includes',
              value: ['created', 'delayed'],
            },
          ],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.invoices.tab.information.title',
            sections: [
              {
                key: 'details',
                title: 'resource.invoices.section.details.title',
                layout: [
                  ['guid'],
                  ['status'],
                  ['user_kind'],
                  ['billing_date'],
                  ['contract_milestone.vat'],
                  ['due_date'],
                  [
                    'contract_milestone.discount',
                    'contract_milestone.payment_period',
                  ],
                ],
                inputs: {
                  guid: {
                    validators: [{ key: 'required' }],
                    label: 'resource.invoices.field.guid_name.label',
                  },
                  status: {
                    disabled: true,
                  },
                  user_kind: {
                    disabled: true,
                  },
                  billing_date: {
                    validators: [{ key: 'required' }],
                  },
                  'contract_milestone.vat': {
                    width: 12,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  due_date: {
                    validators: [{ key: 'required' }],
                  },
                  'contract_milestone.discount': {
                    width: 6,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      {
                        key: 'required',
                      },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                    min: 0,
                    max: 100,
                    step: 0.01,
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'contract_milestone.payment_period': {
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'download',
                title: 'resource.invoices.section.download.title',
                layout: [['pdfs']],
                inputs: {},
                fields: {
                  pdfs: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'multipleLangFiles',
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.invoices.section.infos.title',
              layout: [['id'], ['created_at'], ['updated_at']],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                },
                updated_at: {
                  disabled: true,
                },
              },
              fields: {},
            },
          ],
        },
        {
          sections: [
            {
              key: 'links',
              title: 'resource.invoices.section.links.title',
              layout: [
                ['contract_milestone_id'],
                ['contract_id'],
                ['mission_id'],
                ['proposal_id'],
                ['leader_id'],
                ['leader_company_name'],
                ['client_owner_id'],
                ['client_company_name'],
              ],
              inputs: {},
              fields: {
                contract_milestone_id: {
                  addLabel: true,
                  child: {
                    source: 'contract_milestones.title',
                    type: 'text',
                  },
                },
                contract_id: {
                  addLabel: true,
                  child: {
                    source: 'contracts.id',
                    type: 'text',
                  },
                },
                mission_id: {
                  addLabel: true,
                  label: 'resource.invoices.field.mission_id.label',
                  source: 'mission_id',
                  child: {
                    type: 'text',
                    source: 'missions.name',
                  },
                },
                proposal_id: {
                  addLabel: true,
                  source: 'proposal_id',
                  label: 'resource.invoices.field.proposal_id.label',
                  child: {
                    type: 'text',
                    source: 'proposals.guid',
                  },
                },
                leader_id: {
                  addLabel: true,
                  label: 'resource.proposals.field.candidacy_owner_name.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                leader_company_name: {
                  addLabel: true,
                  link: false,
                  source: 'leader_id',
                  label: 'resource.users.field.company_name_profile.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
                client_owner_id: {
                  addLabel: true,
                  label: 'resource.candidacies.field.company_name.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                client_company_name: {
                  addLabel: true,
                  link: false,
                  source: 'client_owner_id',
                  label: 'resource.missions.field.company_id.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
