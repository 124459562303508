import { Resource as ResourceType } from '../types';

export const company: ResourceType = {
  name: 'companies',
  label: 'resource.companies.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.companies.field.id.label',
      type: 'text',
    },
    {
      key: 'name',
      label: 'resource.companies.field.name.label',
      type: 'text',
    },
    {
      key: 'kind',
      label: 'resource.companies.field.kind.label',
      type: 'select',
      choices: [
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'design_office',
          name: 'resource.companies.field.kind.choices.design_office.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'major_company',
          name: 'resource.companies.field.kind.choices.major_company.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'consulting',
          name: 'resource.companies.field.kind.choices.consulting.label',
        },
        {
          id: 'smes',
          name: 'resource.companies.field.kind.choices.smes.label',
        },
      ],
    },
    {
      key: 'job_name',
      label: 'resource.companies.field.job_name.label',
      type: 'text',
    },
    {
      key: 'solo?',
      label: 'resource.companies.field.solo?.label',
      type: 'boolean',
    },
    {
      key: 'leader_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'cv_download_url',
      label: 'resource.companies.field.cv_download_url.label',
      type: 'file',
    },
    {
      key: 'anonymized',
      label: 'resource.users.field.anonymized.label',
      type: 'boolean',
    },
    {
      key: 'created_at',
      label: 'resource.companies.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.companies.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'updated_at_by_admin',
      type: 'date',
    },
    {
      key: 'skills_name',
      label: 'resource.companies.field.skills_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'sectors',
      label: 'resource.companies.field.sectors.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'retail',
          name: 'resource.companies.field.sectors.choices.retail.label',
        },
        {
          id: 'bank_and_finance',
          name: 'resource.companies.field.sectors.choices.bank_and_finance.label',
        },
        {
          id: 'healthcare',
          name: 'resource.companies.field.sectors.choices.healthcare.label',
        },
        {
          id: 'technology',
          name: 'resource.companies.field.sectors.choices.technology.label',
        },
        {
          id: 'telecom',
          name: 'resource.companies.field.sectors.choices.telecom.label',
        },
        {
          id: 'insurance',
          name: 'resource.companies.field.sectors.choices.insurance.label',
        },
        {
          id: 'manufacturing',
          name: 'resource.companies.field.sectors.choices.manufacturing.label',
        },
        {
          id: 'government',
          name: 'resource.companies.field.sectors.choices.government.label',
        },
        {
          id: 'education',
          name: 'resource.companies.field.sectors.choices.education.label',
        },
        {
          id: 'non_profit',
          name: 'resource.companies.field.sectors.choices.non_profit.label',
        },
        {
          id: 'property',
          name: 'resource.companies.field.sectors.choices.property.label',
        },
        {
          id: 'transport_and_logistics',
          name: 'resource.companies.field.sectors.choices.transport_and_logistics.label',
        },
        {
          id: 'energy_utilities',
          name: 'resource.companies.field.sectors.choices.energy_utilities.label',
        },
        {
          id: 'professional_services',
          name: 'resource.companies.field.sectors.choices.professional_services.label',
        },
        {
          id: 'hospitality_and_tourism',
          name: 'resource.companies.field.sectors.choices.hospitality_and_tourism.label',
        },
        {
          id: 'gaming',
          name: 'resource.companies.field.sectors.choices.gaming.label',
        },
        {
          id: 'blockchain',
          name: 'resource.companies.field.sectors.choices.blockchain.label',
        },
        {
          id: 'media_and_entertainment',
          name: 'resource.companies.field.sectors.choices.media_and_entertainment.label',
        },
        {
          id: 'pharmaceuticals',
          name: 'resource.companies.field.sectors.choices.pharmaceuticals.label',
        },
        {
          id: 'automotive',
          name: 'resource.companies.field.sectors.choices.automotive.label',
        },
        {
          id: 'e_commerce',
          name: 'resource.companies.field.sectors.choices.e_commerce.label',
        },
        {
          id: 'agriculture',
          name: 'resource.companies.field.sectors.choices.agriculture.label',
        },
        {
          id: 'building',
          name: 'resource.companies.field.sectors.choices.building.label',
        },
        {
          id: 'space_and_defence',
          name: 'resource.companies.field.sectors.choices.space_and_defence.label',
        },
        {
          id: 'food_and_beverage',
          name: 'resource.companies.field.sectors.choices.food_and_beverage.label',
        },
      ],
    },
    {
      key: 'name_choice',
      label: 'resource.companies.field.name_choice.label',
      type: 'select',
      choices: [
        {
          id: 'same_as_user_name',
          name: 'resource.companies.field.name_choice.choices.same_as_user_name.label',
        },
        {
          id: 'different_from_user_name',
          name: 'resource.companies.field.name_choice.choices.different_from_user_name.label',
        },
        {
          id: 'portage',
          name: 'resource.companies.field.name_choice.choices.portage.label',
        },
        {
          id: 'none',
          name: 'resource.companies.field.name_choice.choices.none.label',
        },
      ],
    },
    {
      key: 'logo_url',
      label: 'resource.companies.field.logo_url.label',
      type: 'image',
    },
    {
      key: 'about',
      label: 'resource.companies.field.about.label',
      type: 'text',
      multiline: true,
    },
    {
      key: 'size',
      label: 'resource.companies.field.size.label',
      type: 'select',
      choices: [
        {
          id: 'tiny',
          name: 'resource.companies.field.size.choices.tiny.label',
        },
        {
          id: 'small',
          name: 'resource.companies.field.size.choices.small.label',
        },
        {
          id: 'medium',
          name: 'resource.companies.field.size.choices.medium.label',
        },
        {
          id: 'big',
          name: 'resource.companies.field.size.choices.big.label',
        },
        {
          id: 'large',
          name: 'resource.companies.field.size.choices.large.label',
        },
      ],
    },
    {
      key: 'exp_time',
      label: 'resource.companies.field.exp_time.label',
      type: 'number',
    },
    {
      key: 'personal_skills_name',
      label: 'resource.companies.field.personal_skills_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'sub_categories_name',
      label: 'resource.companies.field.sub_categories_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'contract_types',
      label: 'resource.companies.field.contract_types.label',
      type: 'select',
      choices: [
        {
          id: 'fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
        },
        {
          id: 'internship',
          name: 'resource.companies.field.contract_types.choices.internship.label',
        },
        {
          id: 'missions',
          name: 'resource.companies.field.contract_types.choices.missions.label',
        },
        {
          id: 'missions_or_fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.missions_or_fulltime_contract.label',
        },
      ],
    },
    {
      key: 'availability',
      label: 'resource.companies.field.availability.label',
      type: 'select',
      choices: [
        {
          id: 'asap',
          name: 'resource.companies.field.availability.choices.asap.label',
        },
        {
          id: 'precise',
          name: 'resource.companies.field.availability.choices.precise.label',
        },
        {
          id: 'one_month',
          name: 'resource.companies.field.availability.choices.one_month.label',
        },
        {
          id: 'three_months',
          name: 'resource.companies.field.availability.choices.three_months.label',
        },
      ],
    },
    {
      key: 'location',
      type: 'object',
      shape: [
        {
          key: 'address',
          type: 'text',
          label: 'resource.companies.field.location.address.label',
        },
      ],
    },
    {
      key: 'available_at',
      label: 'resource.companies.field.available_at.label',
      type: 'date',
    },
    {
      key: 'search_status',
      label: 'resource.companies.field.search_status.label',
      type: 'select',
      choices: [
        {
          id: 'active',
          name: 'resource.companies.field.search_status.choices.active.label',
        },
        {
          id: 'open',
          name: 'resource.companies.field.search_status.choices.open.label',
        },
        {
          id: 'unavailable',
          name: 'resource.companies.field.search_status.choices.unavailable.label',
        },
      ],
    },
    {
      key: 'billings',
      label: 'resource.companies.field.billings.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'technical_assistance',
          name: 'resource.companies.field.billings.choices.technical_assistance.label',
        },
        {
          id: 'forfeit',
          name: 'resource.companies.field.billings.choices.forfeit.label',
        },
      ],
    },
    {
      key: 'modalities',
      label: 'resource.companies.field.modalities.label',
      type: 'select',
      choices: [
        {
          id: 'remote',
          name: 'resource.companies.field.modalities.choices.remote.label',
        },
        {
          id: 'remote_can_move',
          name: 'resource.companies.field.modalities.choices.remote_can_move.label',
        },
        {
          id: 'on_site',
          name: 'resource.companies.field.modalities.choices.on_site.label',
        },
      ],
    },
    {
      key: 'work_days',
      label: 'resource.companies.field.work_days.label',
      type: 'number',
    },
    {
      key: 'daily_rate',
      label: 'resource.companies.field.daily_rate.label',
      type: 'range',
    },
    {
      key: 'salary',
      label: 'resource.companies.field.salary.label',
      type: 'range',
    },
    {
      key: 'salary_confidentiality',
      label: 'resource.companies.field.salary_confidentiality.label',
      type: 'boolean',
    },
    {
      key: 'leader_kind',
      label: 'resource.companies.field.leader_kind.label',
      type: 'select',
      choices: [
        {
          id: 'client',
          name: 'resource.users.field.kind.choices.client.label',
        },
        {
          id: 'expert',
          name: 'resource.users.field.kind.choices.expert.label',
        },
      ],
    },
    {
      key: 'availability_manually_updated_at',
      label: 'resource.companies.field.availability_manually_updated_at.label',
      type: 'date',
    },
    {
      key: 'on_site_locations',
      label: 'resource.companies.field.on_site_locations.label',
      type: 'array',
      itemSource: 'address',
    },
    {
      key: 'legal_information_id',
      type: 'reference',
      reference: 'legal_informations',
    },
    {
      key: 'recommendation_id',
      type: 'reference',
      reference: 'recommendations',
    },
    {
      key: 'mandatory_skills_name',
      label: 'resource.missions.field.mandatory_skills_name.label',
      type: 'array',
    },
    {
      key: 'fo_link',
      label: 'resource.proposals.field.fo_link.label',
      type: 'frontOfficeLink',
      externalLink: {
        source: 'id',
        path: 'experts',
      },
    },
    {
      key: 'mission_validation',
      label: 'resource.companies.field.mission_validation.label',
      type: 'boolean',
    },
    {
      key: 'candidacy_validation',
      label: 'resource.companies.field.candidacy_validation.label',
      type: 'boolean',
    },
    {
      key: 'search_access',
      label: 'resource.companies.field.search_access.label',
      type: 'boolean',
    },
    {
      key: 'additional_information_access',
      label: 'resource.companies.field.additional_information_access.label',
      type: 'boolean',
    },
    {
      key: 'leader_linkedin_vanity_name',
      label: 'resource.users.field.linkedin_vanity_name.label',
      type: 'url',
    },
    {
      key: 'profile_score',
      label: 'resource.companies.field.profile_score.label',
      type: 'text',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'anonymize',
          type: 'update',
          label: 'resource.companies.bulk_action_buttons.anonymize.label',
          values: {},
          extraUrl: '/anonymize',
          icon: 'VisibilityOff',
          // Following settings are only useful for update with confirmation
          withConfirmation: true,
          confirmation: {
            content: 'modal.bulk_anonymize.content',
          },
        },
      ],
      filters: [
        {
          source: 'kind',
        },
        {
          source: 'users.kind',
          alwaysOn: true,
        },
        {
          source: 'solo?',
        },
        {
          source: 'anonymized',
        },
      ],
      fields: [
        {
          source: 'name',
        },
        {
          source: 'kind',
        },
        {
          source: 'job_name',
        },
        {
          source: 'leader_id',
          link: false,
          label: 'resource.users.field.kind.label',
          child: {
            source: 'users.kind',
            type: 'select',
          },
        },
        {
          source: 'solo?',
          sortable: false,
        },
        {
          source: 'mandatory_skills_name',
          sortable: false,
        },
        {
          source: 'leader_id',
          sortable: false,
          label: 'resource.users.field.email.label',
          child: {
            source: 'users.email',
          },
        },
        {
          source: 'leader_id',
          sortable: false,
          label: 'resource.users.field.phone.label',
          child: {
            source: 'users.phone',
          },
        },
        {
          source: 'leader_linkedin_vanity_name',
          icon: 'LinkedIn',
          sortable: false,
        },
        {
          source: 'fo_link',
          sortable: false,
          icon: 'AccountCircle',
        },
        {
          source: 'id',
          link: false,
          sortable: false,
          label: 'resource.companies.field.competencies_file.label',
          key: 'download',
          type: 'buttons',
          extensions: [
            {
              key: 'competenciesFiles',
              params: {
                formats: ['pdf', 'docx'],
              },
            },
          ],
        },
        {
          source: 'cv_download_url',
          title: 'cv',
          sortable: false,
        },
        {
          source: 'leader_id',
          link: false,
          sortable: false,
          label: 'resource.users.field.relevance_current_score.label',
          child: {
            source: 'users.relevance_current_step_name',
          },
        },
        {
          source: 'leader_id',
          link: false,
          sortable: false,
          label: 'resource.users.field.filled.label',
          child: {
            source: 'users.filled',
          },
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      actions: [
        {
          name: 'anonymize',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.anonymize.label',
          values: {},
          extraUrl: '/anonymize',
          withConfirmation: true,
          confirmation: {
            content: 'modal.anonymize.content',
          },
          icon: 'VisibilityOff',
          disabled: [{ property: 'anonymized', op: 'eq', value: true }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.companies.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.companies.section.profile.title',
                layout: [
                  ['name', 'name_choice'],
                  ['logo_url', 'about'],
                  ['kind', 'size'],
                ],
                inputs: {
                  name: {
                    disabled: true,
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  name_choice: {
                    disabled: true,
                    width: 6,
                  },
                  about: {
                    width: 6,
                    disabled: true,
                    validators: [
                      {
                        key: 'maxLength',
                        value: 10000,
                      },
                    ],
                  },
                  kind: {
                    width: 6,
                    disabled: true,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  size: {
                    width: 6,
                    disabled: true,
                  },
                },
                fields: {
                  logo_url: {
                    addLabel: true,
                    width: 6,
                  },
                },
              },
              {
                key: 'skills',
                title: 'resource.companies.section.skills.title',
                layout: [
                  ['job_name', 'exp_time'],
                  ['skills_name'],
                  ['personal_skills_name'],
                  ['sectors'],
                  ['sub_categories_name'],
                ],
                inputs: {
                  job_name: {
                    width: 6,
                    disabled: true,
                  },
                  exp_time: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'solo?',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                  sectors: { disabled: true },
                },
                fields: {
                  personal_skills_name: {
                    addLabel: true,
                    link: false,
                  },
                  skills_name: {
                    addLabel: true,
                    link: false,
                  },
                  sub_categories_name: {
                    addLabel: true,
                    link: false,
                    translationPrefix: 'sub_categories_name',
                  },
                },
              },
              {
                key: 'modalities',
                title: 'resource.companies.section.modalities.title',
                layout: [
                  ['contract_types', 'availability'],
                  ['available_at', 'search_status'],
                  ['billings', 'work_days'],
                  ['location.address', 'modalities'],
                  ['on_site_locations'],
                  ['daily_rate', 'salary'],
                  ['salary_confidentiality'],
                ],
                inputs: {
                  contract_types: {
                    width: 6,
                    disabled: true,
                  },
                  availability: {
                    width: 6,
                    disabled: true,
                  },
                  available_at: {
                    width: 6,
                    disabled: true,
                  },
                  search_status: {
                    width: 6,
                    disabled: true,
                  },
                  billings: {
                    width: 6,
                    disabled: true,
                  },
                  modalities: {
                    width: 6,
                    disabled: true,
                  },
                  'location.address': {
                    width: 6,
                    disabled: true,
                  },
                  work_days: {
                    width: 6,
                    disabled: true,
                  },
                  daily_rate: {
                    width: 6,
                    disabled: true,
                    options: {
                      min: 100,
                      max: 2500,
                      step: 10,
                    },
                  },
                  salary: {
                    width: 6,
                    disabled: true,
                    options: {
                      min: 10000,
                      max: 500000,
                      step: 1000,
                    },
                  },
                  salary_confidentiality: { disabled: true },
                },
                fields: {
                  on_site_locations: {
                    addLabel: true,
                    link: false,
                  },
                },
              },
              {
                key: 'client_administration',
                title: 'resource.companies.section.client_administration.title',
                layout: [
                  ['mission_validation', 'candidacy_validation'],
                  ['search_access', 'additional_information_access'],
                ],
                rules: {
                  hide: [
                    {
                      property: 'leader_kind',
                      op: 'eq',
                      value: 'expert',
                    },
                  ],
                },
                inputs: {
                  mission_validation: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  candidacy_validation: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  search_access: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  additional_information_access: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
          {
            key: 'matches',
            title: 'resource.companies.tab.matches.title',
            rules: {
              hide: [
                {
                  property: 'leader_kind',
                  op: 'eq',
                  value: 'client',
                },
              ],
            },
            sections: [
              {
                key: 'matches',
                title: 'resource.companies.section.matches.title',
                layout: [],
                inputs: {},
                list: {
                  resource_to_fetch: 'matches',
                  hasCreateButton: false,
                  filter_resource: 'company',
                  fields: [
                    {
                      source: 'id',
                      sortable: false,
                      label: 'resource.matches.field.mission.name.label',
                      child: {
                        source: 'missions.name',
                      },
                    },
                    {
                      source: 'id',
                      sortable: false,
                      label: 'resource.matches.field.mission.context.label',
                      child: {
                        source: 'missions.context',
                      },
                    },
                    {
                      source: 'owner_id',
                      sortable: false,
                      label: 'resource.matches.field.owner_id.label',
                      child: {
                        source: 'users.full_name',
                      },
                    },
                    {
                      source: 'percent',
                      label: 'resource.matches.field.percent.label',
                      type: 'text',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.companies.section.details.title',
              layout: [
                ['id'],
                ['created_at'],
                ['updated_at'],
                ['leader_id', 'leader_kind'],
                ['availability_manually_updated_at'],
                ['legal_information_id'],
                ['recommendation_id'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                },
                updated_at: {
                  disabled: true,
                  extensions: [
                    {
                      key: 'authorType',
                    },
                  ],
                },
                leader_kind: {
                  disabled: true,
                  width: 6,
                },
                availability_manually_updated_at: {
                  disabled: true,
                },
              },
              fields: {
                leader_id: {
                  label: 'resource.companies.field.leader_id.label',
                  addLabel: true,
                  child: {
                    source: 'users.full_name',
                    type: 'text',
                  },
                },
                legal_information_id: {
                  label: 'resource.companies.field.legal_information_id.label',
                  addLabel: true,
                  child: {
                    source: 'legal_informations.id',
                    type: 'text',
                  },
                },
                recommendation_id: {
                  label: 'resource.companies.field.recommendation_id.label',
                  addLabel: true,
                  child: {
                    source: 'recommendations.comment',
                    type: 'recommendation',
                  },
                },
              },
            },
            {
              key: 'files',
              title: 'resource.companies.section.files.title',
              layout: [['competencies_file'], ['cv_download_url']],
              inputs: {},
              fields: {
                competencies_file: {
                  source: 'id',
                  link: false,
                  sortable: false,
                  label: 'resource.companies.field.competencies_file.label',
                  key: 'download',
                  type: 'buttons',
                  extensions: [
                    {
                      key: 'competenciesFiles',
                      params: {
                        formats: ['pdf', 'docx'],
                      },
                    },
                  ],
                },
                cv_download_url: {
                  addLabel: true,
                  title: 'cv',
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'link',
              title: 'resource.companies.section.link.title',
              layout: [['users_link', 'invitations_link', 'candidacies_link']],
              inputs: {},
              fields: {
                users_link: {
                  source: 'id',
                  type: 'resourceLink',
                  label: 'resource.companies.link.users.label',
                  resource: 'users',
                  filters: [
                    {
                      key: 'company_id',
                      source: 'id',
                    },
                  ],
                },
                candidacies_link: {
                  source: 'id',
                  type: 'resourceLink',
                  label: 'resource.companies.link.candidacies.label',
                  resource: 'candidacies',
                  filters: [
                    {
                      key: 'company_id',
                      source: 'id',
                    },
                  ],
                },
                invitations_link: {
                  source: 'id',
                  type: 'resourceLink',
                  label: 'resource.companies.link.invitations.label',
                  resource: 'invitations',
                  rules: {
                    hide: [
                      {
                        property: 'leader_kind',
                        op: 'eq',
                        value: 'client',
                      },
                    ],
                  },
                  filters: [
                    {
                      key: 'recipient_id',
                      source: 'id',
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'history',
              title: 'resource.companies.section.history.title',
              layout: [],
              inputs: {},
              history: true,
            },
          ],
        },
      ],
    },
  },
};
